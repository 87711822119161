<template>
  <div>
    <el-page-header
      class="u-bottom-margin--2x"
      title="Back"
      :content="`assgin featured product`"
      @back="goBack"
    ></el-page-header>
    <el-form :model="addform" :rules="rules" ref="addForm" label-width="150px">
      <el-row :gutter="20">
        <el-col :span="11">
          <el-form-item label="Select product">
            <el-select v-model="addform.product" placeholder="select product">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Select Area">
            <el-select v-model="addform.Area" placeholder="select Area">
              <el-option
                v-for="item in areas"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="place">
            <el-checkbox
              v-model="addform.incategory"
              label="incategory"
            ></el-checkbox>
            <el-checkbox v-model="addform.inHome" label="in home"></el-checkbox>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <div class="btn-group">
            <el-button type="primary" @click="saveForm">Save</el-button>
          </div>
          <div class="image-container u-top-margin--2x">
            <el-form-item label="" label-width="0" prop="imageUrl">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholdesdasdr.typicode.com/posts/"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="onChangeImage"
              >
                <img
                  v-if="addform.imageUrl"
                  :src="addform.imageUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>

    <el-dialog title="Corp Image" :visible.sync="dialogVisible" width="60%">
      <cropper
        classname="cropper"
        :src="addform.imageUrl"
        :stencilProps="{}"
        @change="change"
      ></cropper>
      <el-button @click="dialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="finishCorping">Confirm</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import { mapGetters } from "vuex";
// import { productsService } from "../../services/products.service";

export default {
  components: {
    Cropper,
  },
  data() {
    return {
      toolbar: false,

      addform: {
        itemsPerUnit: 1,
        name: "",
        unit: "",
        manufacturer: "",
        subcategoryId: "",
        tradeMark: "",
        imageUrl: "",
        maxPerOrder: 1,
      },
      rules: {
        itemsPerUnit: [
          {
            pattern: /\D*([0-9])\D*/,
            message: "must be a number",
            trigger: "change",
          },
        ],
        maxPerOrder: [
          {
            pattern: /\D*([0-9])\D*/,
            message: "must be a number",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "Please input Product name",
            trigger: "blur",
          },
        ],
        unit: [
          {
            required: true,
            message: "Please input Product unit",
            trigger: "blur",
          },
        ],
        size: [
          {
            required: true,
            message: "Please input Product size",
            trigger: "blur",
          },
        ],
        manufacturer: [
          {
            required: true,
            message: "Please select manufacture company",
            trigger: "change",
          },
        ],
        tradeMark: [
          {
            required: true,
            message: "Please select product trademark ",
            trigger: "change",
          },
        ],
        subcategoryId: [
          {
            required: true,
            message: "Please select product Category ",
            trigger: "change",
          },
        ],
        imageUrl: [
          {
            required: true,
            message: "Please select product Image ",
            trigger: "change",
          },
        ],
      },
      dialogVisible: false,
      blob: {},
    };
  },
  computed: {
    ...mapGetters({
      // lookups: "getAllLookups",
    }),
  },
  methods: {
    finishCorping() {
      this.addform.imageUrl = URL.createObjectURL(this.blob);
      this.dialogVisible = false;
    },
    change(value) {
      value.canvas.toBlob((blob) => {
        console.log(blob.raw);
        this.blob = blob;
      });
    },
    handleChange(e, key) {
      this.addform[key] = parseInt(e.target.value);
    },
    changeval(e, key) {
      this.addform[key] = e.target.value;
    },
    // async saveForm() {
    //   let isValid = await this.$refs["addForm"].validate();
    //   if (isValid) {
    //     let pageLoading = this.$loading();
    //     let productObj = { ...this.addform };
    //     productObj.itemsPerUnit = `${productObj.itemsPerUnit} X`;
    //     try {
    //       await productsService.addProduct(productObj);

    //       this.$notify({
    //         title: "Success",
    //         message: "This is product Created sucessufly",
    //         type: "success",
    //       });

    //       this.addform = {};
    //     } catch (err) {
    //       console.log("error");

    //       this.$notify({
    //         title: "Error ",
    //         message: "some thing went wrong",
    //         type: "error",
    //       });
    //     }
    //     pageLoading.close();
    //   }
    // },
    goBack() {
      this.$router.go(-1);
    },

    onChangeImage(file) {
      let reader = new FileReader();
      let isRightResloution = false;
      reader.readAsDataURL(file.raw);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          isRightResloution =
            img.width >= 300 &&
            img.width <= 700 &&
            img.height >= 300 &&
            img.height <= 700;
          if (!isJPG) {
            this.$message.error("Avatar picture must be JPG format!");
            return;
          }

          if (!isLt2M) {
            this.$message.error("Avatar picture size can not exceed 2MB!");
            return;
          }
          if (!isRightResloution) {
            this.$message.error(
              "Product Image resolution must be in range form  300x300 to 700x700 "
            );
            return;
          }

          this.addform.imageUrl = URL.createObjectURL(file.raw);
          this.dialogVisible = true;
        };
        const isJPG = file.raw.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;

        img.src = evt.target.result;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
.btn-group {
  display: flex;
  justify-content: center;
}
// upload image folder
.image-container {
  display: flex;
  justify-content: center;

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px !important;
    text-align: center;
  }
  .avatar {
    width: 355px;
    height: 355px;
    display: block;
  }
}
.cropper {
  height: 500px;
}
</style>
